
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function scopeColorArrColorImageUrl1(colorOption, colorOptionIndex) {
                    var colorArr = colorOption.split('|');
                    var color = colorArr[0];
                    var image = colorArr[1];
                    var url = colorArr[2];
                    return [_createElement('a', {
                            'className': 'product-color-swatch' + (colorOptionIndex >= 4 ? ' hidden' : ''),
                            'data-product-id': 'product-' + this.id,
                            'data-option-image': image,
                            'title': color,
                            'href': url,
                            'style': {
                                backgroundColor: color,
                                backgroundImage: 'url(' + image + ')'
                            },
                            'key': '55911'
                        }, '\n        ', color, '\n      ')];
                }
                function repeatColorOption2(colorOption, colorOptionIndex, colorArr, color, image, url) {
                    return scopeColorArrColorImageUrl1.apply(this, [
                        colorOption,
                        colorOptionIndex
                    ]);
                }
                return _createElement('div', { 'className': 'halo-item' }, _createElement('div', {
                    'className': 'product-card layout-1',
                    'data-product-id': 'product-' + this.id,
                    'data-id': this.id
                }, _createElement('div', { 'className': 'product-image' }, window.themeSettings.show_wishlist === 'true' ? _createElement('a', {
                    'className': 'product_wishlist wishlist',
                    'href': '#',
                    'aria-label': 'wishlist',
                    'data-icon-wishlist': true,
                    'data-product-handle': this.handle,
                    'data-id': this.id,
                    'key': '156'
                }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-heart-fill' }))) : null, _createElement('div', { 'className': 'product_badges' }, window.Convermax.productIsNew(this.published_at) ? _createElement('div', {
                    'className': 'badge new-badge',
                    'key': '510'
                }, '\n          ', window.themeSettings.locales.new, '\n        ') : null, !this.out_of_stock && this.on_sale ? _createElement('div', {
                    'className': 'badge sale-badge sale-badge-2',
                    'key': '665'
                }, '\n          -', this.calcDiscount(this.price, this.compare_at_price), '%\n        ') : null, this.out_of_stock ? _createElement('div', {
                    'className': 'badge soldOut-badge',
                    'key': '850'
                }, '\n          ', window.themeSettings.locales.sold_out, '\n        ') : null, !this.out_of_stock && this.stock && (this.stock?.includes('Available') && !this.stock?.includes('In-stock Only')) ? _createElement('div', {
                    'className': 'badge specialOrder-badge',
                    'key': '983'
                }, '\n          ', window.themeSettings.locales.special_order, '\n        ') : null), _createElement('a', {
                    'className': 'product-link ' + (this.image2 ? 'image-swap' : ''),
                    'href': this.url,
                    'aria-label': 'link'
                }, _createElement('img', {
                    'className': (this.image2 ? 'image-one ' : '') + 'lazyautosizes lazyloaded',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'data-src': this.image,
                    'data-sizes': 'auto',
                    'alt': this.removeHTML(this.title),
                    'sizes': '285px',
                    'onError': this.onImageError
                }), this.image2 ? _createElement('img', {
                    'className': 'image-two lazyautosizes lazyloaded',
                    'src': this.resizeImage(this.image2),
                    'data-src': this.image2,
                    'data-sizes': 'auto',
                    'alt': this.removeHTML(this.title),
                    'sizes': '285px',
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '1683'
                }) : null), window.themeSettings.quick_view_enabled === 'true' ? _createElement('a', {
                    'className': 'product-quickview',
                    'aria-label': 'quickview',
                    'id': this.handle,
                    'data-quickview': true,
                    'aria-describedby': 'a11y-external-message',
                    'key': '1963'
                }, '\n        ', window.themeSettings.locales.quick_view, '\n      ') : null, window.themeSettings.show_product_button === 'true' ? _createElement('div', {
                    'className': 'product-action',
                    'key': '2231'
                }, _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'variants',
                    'id': 'list-product-form-' + this.id,
                    'data-id': 'product-actions-' + this.id,
                    'encType': 'multipart/form-data'
                }, this.variant_ids.length > 1 ? [_createElement('a', {
                        'className': 'btn product-btn',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '25041'
                    }, '\n              ', this.out_of_stock ? window.themeSettings.locales.sold_out : this.stock && (this.stock?.includes('Available') && !this.stock?.includes('In-stock Only')) ? window.themeSettings.locales.special_order : window.themeSettings.locales.select_options, '\n            ')] : null, this.variant_ids.length === 1 ? [
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids,
                        'key': '30091'
                    }),
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': '1',
                        'key': '30093'
                    }),
                    _createElement('button', {
                        'data-btn-addtocart': true,
                        'className': 'btn product-btn',
                        'type': 'submit',
                        'data-form-id': '#list-product-form-' + this.id,
                        'disabled': this.out_of_stock ? true : false,
                        'key': '30095'
                    }, '\n              ', this.out_of_stock ? window.themeSettings.locales.sold_out : this.stock && (this.stock?.includes('Available') && !this.stock?.includes('In-stock Only')) ? window.themeSettings.locales.special_order : window.themeSettings.locales.add_to_cart, '\n            ')
                ] : null)) : null), _createElement('div', { 'className': 'product-content' }, _createElement('div', { 'className': 'product-detail' }, window.themeSettings.show_product_vendor === 'true' ? _createElement('div', {
                    'className': 'product-vendor',
                    'key': '3849'
                }, _createElement('a', Object.assign({}, {
                    'href': this.vendor_url,
                    'title': this.removeHTML(this.vendor)
                }, { dangerouslySetInnerHTML: { __html: this.vendor } }))) : null, window.themeSettings.show_product_title === 'true' ? _createElement('h4', {
                    'className': 'product-title',
                    'key': '4112'
                }, _createElement('a', Object.assign({}, {
                    'href': this.url,
                    'aria-label': 'title'
                }, { dangerouslySetInnerHTML: { __html: this.breakWordOnSlash(this.title) } }))) : null, window.themeSettings.show_product_reviews === 'true' ? _createElement('div', {
                    'className': 'product-reviews',
                    'key': '4366'
                }, _createElement('span', {
                    'className': 'shopify-product-reviews-badge',
                    'data-id': this.id
                })) : null, this.on_sale ? _createElement('div', {
                    'className': 'product-price price--on-sale',
                    'key': '4567'
                }, _createElement('span', { 'className': 'price-item price-item--regular' }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.compare_at_price))), _createElement('span', {
                    'className': 'price-item price-item--sale',
                    'data-sale-price': true
                }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.price)))) : null, !this.on_sale ? _createElement('div', {
                    'className': 'product-price',
                    'key': '4970'
                }, _createElement('span', { 'className': 'price-item price-item--regular' }, this.price_varies ? _createElement('span', { 'key': '5088' }, 'From: ') : null, _createElement('span', { 'className': 'money' }, this.formatPrice(this.price)))) : null, this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '5245'
                }) : null))), window.themeSettings.show_product_color_swatch && this.product_colors && this.product_colors.length > 1 ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'product-color-swatch-container',
                        'key': '5411'
                    },
                    _map(this.product_colors, repeatColorOption2.bind(this)),
                    this.product_colors.length > 5 ? [_createElement('a', {
                            'className': 'product-color-swatch show-more',
                            'key': '60821'
                        }, '+', this.product_colors.length - 4)] : null
                ]) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_SearchResult product-recommendations__items' }, _createElement.apply(this, [
        'div',
        {
            'className': 'halo-row halo-row-custom column-' + (window.Convermax.productRecommendationsSettings ? window.Convermax.productRecommendationsSettings.column : '2') + ' ' + (window.Convermax.productRecommendationsSettings && window.Convermax.productRecommendationsSettings.carousel ? 'collection-carousel' : '') + ' cmRepeater_items',
            'data-slick': window.Convermax.productRecommendationsSettings ? window.Convermax.productRecommendationsSettings.dataSlick : ''
        },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []